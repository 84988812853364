var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "butler-mgr-view-wrapper" },
    [
      _c(
        "form-panel",
        { ref: "form" },
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基本信息" } },
                [
                  _c("el-form-item", { attrs: { label: "管家姓名" } }, [
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.detail.userName) +
                          "-" +
                          _vm._s(_vm.detail.mobileNum)
                      ),
                    ]),
                  ]),
                  _c("el-form-item", { attrs: { label: "所属项目" } }, [
                    _c("div", [_vm._v(_vm._s(_vm.detail.communityName))]),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { label: "服务区域" } },
                    [
                      _c("table-panel", {
                        attrs: {
                          headers: _vm.tableHeaders,
                          tableData: _vm.tableData,
                          hasOperateColumn: false,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }