<template>
  <div class="butler-mgr-view-wrapper">
    <form-panel ref="form">
      <col2-detail>
        <col2-block title="基本信息">
          <el-form-item label="管家姓名">
            <div>{{detail.userName}}-{{detail.mobileNum}}</div>
          </el-form-item>
          <el-form-item label="所属项目">
            <div>{{detail.communityName}}</div>
          </el-form-item>
          <el-form-item label="服务区域">
            <table-panel :headers="tableHeaders" :tableData="tableData" :hasOperateColumn="false"></table-panel>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>
<script>
import { queryURL } from './api'
import { Col2Detail, Col2Block, TablePanel } from 'components/bussiness'
export default {
  name: 'ButlerMgrView',
  components: {
    Col2Detail,
    Col2Block,
    TablePanel
  },
  data () {
    return {
      detail: {},
      tableHeaders: [
        {
          label: '项目名称',
          prop: 'communityName'
        },
        {
          label: '苑',
          prop: 'blockName'
        },
        {
          label: '幢',
          prop: 'buildingNumber'
        },
        {
          label: '单元',
          prop: 'unit'
        },
        {
          label: '室',
          prop: 'room'
        }
      ],
      tableData: []
    }
  },
  methods: {
    async fetchDetail (userId, communityId) {
      let { data } = await this.$axios.get(queryURL, {
        params: {
          userId,
          communityId
        }
      })
      this.detail = data[0]
      this.tableData = data
    }
  },
  mounted () {
    const { userId, communityId } = this.$route.query
    this.fetchDetail(userId, communityId)
    this.$setBreadcrumb('详情')
  }
}
</script>
<style lang="scss" scoped>

</style>
